export let BASE_URL_API;
export let ID_URL;
export let BASE_URL_API2;
export let ORIGIN;
export let ORIGIN2;

if (process.env.NODE_ENV == 'development') {
  BASE_URL_API = 'https://api.sdleiloes.com.br/';
  BASE_URL_API2 = 'https://api.sdleiloes.com.br/';
  ID_URL = 'https://id.sdleiloes.com.br/';
  ORIGIN = 'http://localhost:8080';
  ORIGIN2 = 'https://localhost:8080';
} else {
  BASE_URL_API = 'https://api.sdleiloes.com.br/';
  BASE_URL_API2 = 'https://api.sdleiloes.com.br/';
  ID_URL = 'https://id.sdleiloes.com.br/';
  ORIGIN = 'https://sdleiloes.com.br';
  ORIGIN2 = 'https://painel.sdleiloes.com.br';
}
