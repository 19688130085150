<template>
  <v-app>
    <v-app-bar app height="48" elevation="1" v-if="getAuth">
      <img
        :src="require('@/assets/logo.png')"
        height="30"
        style="vertical-align: middle; padding-right: 15px"
        v-if="!mobile"
      />
      <img
        :src="require('@/assets/logo_mobile.png')"
        height="30"
        style="vertical-align: middle; padding-right: 15px"
        v-else
      />
      <h4>
        {{ tituloPagina }}
      </h4>
      <v-spacer></v-spacer>
      <span class="pr-2 hidden-sm-and-down" v-if="getInfo">{{
        getInfo.full_name
      }}</span>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <img
            :src="getFotoUsuarioLogado"
            alt="foto"
            class="rounded-circle user-button"
            width="40px"
            v-bind="attrs"
            v-on="on"
            v-if="!!getFotoUsuarioLogado"
          />
          <v-avatar color="primary" size="36" v-bind="attrs" v-on="on" v-else
            ><span class="white--text text-h6">{{
              getLetraNome
            }}</span></v-avatar
          >
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="logout" text>
                <v-icon icon>mdi-logout</v-icon> SAIR
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ORIGIN, ORIGIN2 } from '@/config';
export default {
  name: 'App',

  data: () => ({
    window: null,
    items: [{ title: 'Sair' }],
  }),
  computed: {
    ...mapState(['tituloPagina']),
    ...mapGetters(['getAuth', 'getInfo', 'getFotoUsuarioLogado']),
    getLetraNome() {
      return this.getInfo?.full_name?.charAt(0);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions(['logout', 'gInfo']),
    ...mapMutations(['SET_AUTH', 'SET_LEILAO', 'SET_CLIENT_APP']),
    getTokenFromSite() {
      window.addEventListener(
        'message',
        async (event) => {
          if (
            (event.origin === ORIGIN || event.origin === ORIGIN2) &&
            !!event.data.auth &&
            !!event.data.auth.access_token
          ) {
            this.SET_AUTH({ ...event.data.auth });
            this.SET_LEILAO({ id: event.data.leilaoId });
            this.$nextTick(() => {
              this.gInfo();
              this.SET_CLIENT_APP('siteebl');
              this.$router.push({ name: 'AuditorioVirtual' });
            });

            event.source.postMessage('Recebido', event.origin);
          }
          return;
        },
        false
      );
    },
  },
  created() {
    this.getTokenFromSite();
  },
};
</script>
<style>
.square.v-btn:not(.v-btn--round).v-size--small {
  min-width: 28px;
  padding: 0;
}

.row {
  margin: 0px !important;
}
</style>
