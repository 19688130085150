import axios from 'axios';
import store from '@/store';
import { BASE_URL_API, BASE_URL_API2, ORIGIN } from '@/config/index.js';
import jwt from 'jsonwebtoken';
import router from '@/router';

const instance = axios.create({
  baseURL: BASE_URL_API,
  timeout: 100000,
});

instance.interceptors.request.use(async function (config) {
  let token = store.getters.getAuth && store.getters.getAuth.access_token;
  if (!token) {
    try {
      const decoded = await jwt.decode(token);
      if (Date.now() >= decoded.exp * 1000) {
        if (store.getters.getClientApp === 'siteebl') {
          window.location.href = ORIGIN;
        } else {
          router.push({ name: 'Login' });
        }
      }
    } catch (error) {
      //console.error("token error:", error);
    }
  }

  config.headers.Authorization = 'Bearer ' + token;
  if (store?.getters?.getClientApp === 'siteebl') {
    config.baseURL = BASE_URL_API2;
  }
  return config;
});

export default instance;
